import axios from '../../plugins/axios'
// 查询出栏信息列表
const listFowlOuts = (options) => axios.post('/vervel/listFowlOuts', options)
// 查询出栏信息
const getFowlOut = (options) => axios.post('/vervel/getFowlOut', options)
// 增加出栏信息
const saveFowlOut = (options) => axios.post('/vervel/saveFowlOut', options)
// 修改出栏信息
const updateFowlOut = (options) => axios.post('/vervel/updateFowlOut', options)
// 删除出栏信息
const removeFowlOut = (options) => axios.post('/vervel/removeFowlOut', options)
// 查询鸡脚环步数明细列表
const listVervels = (options) => axios.post('/vervel/listVervels', options)

export default {
  listFowlOuts,
  getFowlOut,
  saveFowlOut,
  updateFowlOut,
  removeFowlOut,
  listVervels
}
