<template>
<!-- 公共上传文件组件 -->
<!-- uploadFileUrl是在src/config/index.js配置的文件上传路径 -->
  <el-upload
    class="ym-file-uploader"
    :with-credentials="true"
    :action="uploadFileUrl"
    :data="{businessType: businessType}"
    :name="name"
    :limit="limit"
    :show-file-list="showFileList"
    :list-type="fileListType"
    :file-list="fileList"
    :on-exceed="onExceed"
    :on-success="onSuccess"
    :before-upload="beforeUpload"
    :on-remove="onRemove"
    >

    <slot>
      <el-button>上传</el-button>
    </slot>
    <span slot="tip" class="ym-file-uploader__slot"><slot name="tip"></slot></span>

  </el-upload>
</template>

<script>

export default {
  name: 'uploadFile',
  props: {
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    limit: {
      type: Number,
      default: 1
    },
    // 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 显示已上传文件列表类型
    fileListType: {
      type: String,
      default: 'text'
    },
    // 文件对应字段，看接口要求
    name: {
      type: String,
      default: 'multipartFile'
    },
    // 上传接口需要传入的businessType字段：业务类型 order=订单，company=公司文件，user=用户文件
    businessType: {
      type: String,
      default: 'company'
    },
    // 文件格式不匹配的错误提示文本
    typeErrorMsg: {
      type: String,
      default: '请按要求的格式上传文件'
    },
    // 文件大小限制，k为单位，默认限制1M以内，即1024k
    sizeLimit: {
      type: Number,
      default: 1024
    },
    // 用正则来校验fileName的后缀名，来校验文件类型，考虑Mac上的兼容性，通过fileName来判断文件类型，默认支持图片类型
    acceptTypes: {
      type: RegExp,
      default: () => {
        return /.jpg$|.jpeg$|.png$|.bmp$|.JPG$|.JPEG$|.PNG$|.BMP$/
      }
    }
  },
  data() {
    return {
      loading: false,
      options: [],
      keywords: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      }
    }
  },
  computed: {},
  watch: {
    params: {
      handler: function(val) {
        this.getList()
      },
      deep: true
    }
  },
  created() {
  },
  methods: {
    /** 文件上传成功 */
    onSuccess(res, file) {
      if (res.code === '0') {
        this.$emit('onSuccess', res, file)
      } else {
        this.$message.error(res.msg || '上传失败')
      }
    },
    onRemove(file, fileList) {
      this.$emit('onRemove', file, fileList)
    },
    // beforeRemove(file, fileList) {
    //   return this.$confirm(`确定移除 ${file.name}？`)
    // },
    onExceed() {
      this.$message.warning(`限制上传${this.limit}个文件,如果要重新上传，请先删除已选记录`)
      this.$emit('onExceed')
    },
    /**
     * 上传之前的文件检查
     * */
    beforeUpload(file) {
      const acceptTypes = this.acceptTypes
      const isLimit = file.size / 1024 < this.sizeLimit
      const fileName = file.name
      if (!fileName || !acceptTypes.test(fileName)) {
        this.$message.error(this.typeErrorMsg)
        return false
      }

      if (!isLimit) {
        this.$message.error(`上传图片大小不能超过 ${(this.sizeLimit / 1024).toFixed(2)}MB!`)
      }
      return isLimit
    }

  }
}
</script>

<style lang="scss">
  $primary: #3E6DF5;
  $error: #ff5700;
  .ym-file-uploader {
    .el-upload-list{
      display: inline-block;
      vertical-align: top;
    }
.ym-file-uploader__slot{
  padding-left: 20px;
}
  }
</style>
