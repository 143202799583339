import axios from '../../plugins/axios'
// 查询育雏信息列表
const listBroods = (options) => axios.post('/vervel/listBroods', options)
// 查询育雏信息
const getBrood = (options) => axios.post('/vervel/getBrood', options)
// 增加育雏信息
const saveBrood = (options) => axios.post('/vervel/saveBrood', options)
// 修改育雏信息
const updateBrood = (options) => axios.post('/vervel/updateBrood', options)
// 删除育雏信息
const removeBrood = (options) => axios.post('/vervel/removeBrood', options)

export default {
  listBroods,
  getBrood,
  saveBrood,
  updateBrood,
  removeBrood
}
