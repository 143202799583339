const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userInfo: state => {
    if (state.user.userInfo === 'undefined' || state.user.userInfo === undefined) {
      return null
    }
    return JSON.parse(state.user.userInfo)
  },
  routers: state => state.role.routers
}
export default getters
