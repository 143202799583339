import Vue from 'vue'
import GlobalConfig from '@/utils/getConfig.js'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import myComponents from './components'
import './assets/css/main.scss'
import './assets/icon/iconfont.css'
// import velocity from './plugins/velocity'
import filters from './plugins/filters'
import api from './service'
// import uploader from 'vue-simple-uploader'

import '@/utils/permission.js'
import '@/directive'

// Vue.use(uploader)
Vue.use(myComponents)
Vue.use(GlobalConfig)
// Vue.use(velocity)
Vue.use(filters)
Vue.config.productionTip = false

Vue.prototype.$http = api

if (
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style
) { // detect it's IE11
  window.addEventListener('hashchange', function(event) {
    var currentPath = window.location.hash.slice(1)
    if (store.state.route.path !== currentPath) {
      router.push(currentPath)
    }
  }, false)
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
