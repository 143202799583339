<template>
  <el-menu class="ym-menu" router @select="onSelect" :default-active="activePath">
    <template v-for="(item, index) in menus">

      <el-menu-item :index="item.path" :key="item.path+''+index">
        <i v-if="item.icon" class="ym-menu-icon" :class="item.icon" alt=""></i>
        <i v-if="item.selectedIcon" class="ym-menu-icon" :class="item.selectedIcon" alt=""></i>
        <span>{{item.title}}</span>
      </el-menu-item>

    </template>
  </el-menu>
</template>

<script>
import { getActiveMenu, setActiveMenu } from '@/utils/auth.js'
export default {
  name: 'ymMenu',
  data() {
    return {
      activePath: getActiveMenu() || this.$route.path // 选中当前路由的菜单
    }
  },
  created() {
    this.changePath()
    console.log(this.menus)
  },
  computed: {
    menus() {
      this.changePath()
      const routes = this.$router.options.routes
      console.log(routes)
      const res = []
      if (!routes || routes.length === 0) {
        return res
      }
      let child = []
      if (this.$route.path !== '/setting' && this.$route.path !== '/setting/changeInfor' && this.$route.path !== '/home') {
        const route = routes[2]
        if (!route.children || route.children.length === 0) {
          if (route.meta.menuShow && route.meta.menuName) {
            const menu = {
              path: route.path,
              title: route.meta.menuName,
              icon: route.meta.icon,
              selectedIcon: routes[2].meta.selectedIcon
            }
            res.push(menu)
          }
        } else {
          child = route.children.filter(r => r.meta.menuShow)
          for (let i = 0; i < child.length; i++) {
            const son = child[i]
            const menu = {
              path: son.path,
              title: son.meta.menuName,
              icon: son.meta.icon,
              selectedIcon: son.meta.selectedIcon
            }
            res.push(menu)
          }
        }
      } else if (this.$route.path === '/setting' || this.$route.path === '/setting/changeInfor') {
        console.log('设置:', this.$route.path)
        const route = routes[3]
        if (!route.children || route.children.length === 0) {
          if (route.meta.menuShow && route.meta.menuName) {
            const menu = {
              path: route.path,
              title: route.meta.menuName,
              icon: route.meta.icon,
              selectedIcon: routes[3].meta.selectedIcon
            }
            res.push(menu)
          }
        } else {
          child = route.children.filter(r => r.meta.menuShow)
          for (let i = 0; i < child.length; i++) {
            const son = child[i]
            const menu = {
              path: son.path,
              title: son.meta.menuName,
              icon: son.meta.icon,
              selectedIcon: son.meta.selectedIcon
            }
            res.push(menu)
          }
        }
      }
      console.log('菜单输出:', res)
      return res
    }
  },
  methods: {
    onSelect(index) {
      setActiveMenu(index)
      this.activePath = index
    },
    changePath() {
      // this.activePath = this.$route.path
      console.log('选中路由:', this.activePath)
      if (this.$route.path !== '/home' && this.$route.path !== '/setting' && this.$route.path !== '/setting/changeInfor') {
        if (this.$route.path === '/listBroods' || this.$route.path === '/listBroods/saveBrood' || this.$route.path === '/listBroods/getBrood') {
          this.activePath = '/listBroods'
        } else if (this.$route.path === '/listFeeds' || this.$route.path === '/listFeeds/saveFeed' || this.$route.path === '/listFeeds/getFeed') {
          this.activePath = '/listFeeds'
        } else if (this.$route.path === '/listFowlOuts' || this.$route.path === '/listFowlOuts/saveFowlOut' || this.$route.path === '/listFowlOuts/getFowlOut') {
          this.activePath = '/listFowlOuts'
        } else if (this.$route.path === '/listQuarantines' || this.$route.path === '/listQuarantines/saveQuarantine' || this.$route.path === '/listQuarantines/getQuarantine') {
          this.activePath = '/listQuarantines'
        }
      } else if (this.$route.path === '/home') {
        this.activePath = '/home'
      } else {
        this.activePath = '/setting'
        console.log(this.activePath)
      }
    }
  }
}
</script>

<style lang="scss">
  $primary: #fff;
  .el-menu.ym-menu {
    border-right: 0;
    background-color: $primary;
    .el-menu-item {
      height: 52px;
      line-height: 52px;
      font-size: 16px;
      border-radius:0px 100px 100px 0px;
      color: #999999;
      margin-top: 20px;
      i {
        color: inherit;
      }
      &:hover,&:focus{
        background-color:rgba(238,242,254,1);
        color: #3E6DF5;
      }
      &.is-active{
        color:#3E6DF5;
        background-color:rgba(238,242,254,1);
        &::before{
          content: '';
          position: absolute;
          display: inline-block;
          left: 0;
          top: 10px;
          width:6px;
          height:32px;
          background-color:#3E6DF5;
          border-radius:0px 100px 100px 0px;
        }
      }
    }
    .el-menu[role="menu"] {
      padding-left: 20px;
    }
    .el-menu-item [class^=el-icon-] {
      font-size: 30px;
    }
    .el-submenu [class^=el-icon-] {
      font-size: 30px;
    }
    .el-submenu__title {
      font-size: 18px;
    }

    .ym-menu-icon {
      display: inline-block;
      font-size: 20px;
      vertical-align: middle;
      margin-right: 14px;
      width: 24px;
    }
    .el-submenu__icon-arrow {
      font-size: 16px;
      transform: rotate(-90deg);
    }
    .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
      transform: rotateZ(0);
    }
    .el-submenu.is-active {
      color: $primary;
        background-color: #fff;
      .el-submenu__title{
        color:$primary;
        i{
          color:$primary;
        }
      }
    }
  }
</style>
