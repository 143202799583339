import Cookies from 'js-cookie'

const TokenKey = 'ym-gdqy-b-token'

const UserInfo = 'ym-gdqy-b-userinfo'

const ActiveMenu = 'ym-gdqy-b-active-menu'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  // console.log(token)
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  return Cookies.get(UserInfo)
}

export function setUserInfo(info) {
  return Cookies.set(UserInfo, info)
}

export function removeUserInfo() {
  return Cookies.remove(UserInfo)
}

export function getActiveMenu() {
  return Cookies.get(ActiveMenu)
}

export function setActiveMenu(active) {
  return Cookies.set(ActiveMenu, active)
}

export function removeActiveMenu() {
  return Cookies.remove(ActiveMenu)
}
