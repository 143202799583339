<template>
  <el-input
    class="input-password"
    ref="elInput"
    v-if="compatible"
    v-on="$listeners"
    v-bind="{
      ...$attrs,
      ...$props
    }">
    <slot name="suffix" slot="suffix"></slot>
    <slot name="prepend" slot="prepend"></slot>
  </el-input>
  <el-input
    v-else
    ref="elInput"
    v-bind="{
      ...$attrs,
      ...$props,
      showPassword: false,
      type: 'text',
      value: password
    }"
    v-on="{
      ...$listeners,
      input: handleInput,
      blur: handleBlur
    }">
    <i
      v-show="currentValue"
      slot="suffix"
      class="el-input__icon el-icon-view el-input__clear"
      @click="handlePasswordVisible"
    ></i>
    <slot name="prepend" slot="prepend"></slot>
  </el-input>
</template>

<script>
import ElInput from 'element-ui/packages/input'

export default {
  props: ElInput.props,
  components: {
    'el-input': ElInput
  },
  data() {
    const value = this.value || ''
    return {
      passwordVisible: false,
      compatible: false,
      olbCursort: {
        value: value,
        selectionStart: value.length,
        selectionEnd: value.length,
        selectionText: ''
      },
      cursort: {
        value: value,
        selectionStart: value.length,
        selectionEnd: value.length,
        selectionText: ''
      }
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value || ''
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    password() {
      if (this.passwordVisible) return this.currentValue
      return this.currentValue.replace(/./g, '*')
    }
  },
  watch: {
    value() {
      this.currentValue = this.value
    }
  },
  mounted() {
    this._$input = this.$refs.elInput.getInput()
    try {
      Object.assign(this.olbCursort, this.getCursort(this._$input))
      this._$input.addEventListener('keydown', this.handleKeydown.bind(this))
    } catch (error) {
      this.compatible = true
    }
  },
  methods: {

    // 获取光标属性
    getCursort(el) {
      const value = el.value
      let selectionStart = el.value.length
      let selectionText = ''
      let selectionEnd = el.value.length

      if (document.selection) {
        // IE Support
        el.focus()
        var range = document.selection.createRange()

        var storedRange = range.duplicate()
        storedRange.moveToElementText(el)

        el.selectionStart = storedRange.text.length - range.text.length
        el.selectionEnd = el.selectionStart + range.text.length

        selectionStart = el.selectionStart
        selectionEnd = el.selectionEnd
        selectionText = range.text
      } else if (el.selectionStart || el.selectionStart === 0) {
        selectionStart = el.selectionStart
        selectionEnd = el.selectionEnd
        selectionText = value.substring(selectionStart, selectionEnd)
      }

      return { value, selectionStart, selectionEnd, selectionText }
    },

    handleKeydown(event) {
      Object.assign(this.olbCursort, this.getCursort(this._$input))
    },

    // 更改事件
    handleInput(ciphertext) {
      if (!ciphertext || this.passwordVisible) {
        this.currentValue = ciphertext
        return
      }
      Object.assign(this.cursort, this.getCursort(this._$input))

      const { cursort, olbCursort } = this

      let value = this.currentValue

      let start, content, end

      if (olbCursort.selectionStart === olbCursort.selectionEnd) {
        if (ciphertext.length > value.length) {
          start = value.slice(0, olbCursort.selectionStart)
          content = ciphertext.substr(cursort.selectionStart - (ciphertext.length - value.length), ciphertext.length - value.length)
          end = value.slice(olbCursort.selectionStart, value.length)
        } else {
          start = value.slice(0, olbCursort.selectionStart - 1)
          content = ''
          end = value.slice(olbCursort.selectionStart, value.length)
        }
      } else {
        // 更改选择字符
        start = value.slice(0, olbCursort.selectionStart)
        end = value.slice(olbCursort.selectionEnd, value.length)
        if (value.length - ciphertext.length === olbCursort.selectionText.length) {
          content = ''
        } else {
          content = ciphertext.substr(cursort.selectionStart - 1, 1)
        }
      }

      value = start + content + end

      this.currentValue = value
    },

    // 失去焦点
    handleBlur(event) {
      this.$emit('input', this.currentValue)
      this.$emit('blur', event)
    },

    handlePasswordVisible() {
      this.passwordVisible = !this.passwordVisible
    }
  }
}
</script>

<style scoped>
.input-password >>> input {
  ime-mode: disabled !important;
}
</style>
