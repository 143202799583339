var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.compatible)?_c('el-input',_vm._g(_vm._b({ref:"elInput",staticClass:"input-password"},'el-input',Object.assign({}, _vm.$attrs,
    _vm.$props),false),_vm.$listeners),[_vm._t("suffix",null,{"slot":"suffix"}),_vm._t("prepend",null,{"slot":"prepend"})],2):_c('el-input',_vm._g(_vm._b({ref:"elInput"},'el-input',Object.assign({}, _vm.$attrs,
    _vm.$props,
    {showPassword: false,
    type: 'text',
    value: _vm.password}),false),Object.assign({}, _vm.$listeners,
    {input: _vm.handleInput,
    blur: _vm.handleBlur})),[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"el-input__icon el-icon-view el-input__clear",attrs:{"slot":"suffix"},on:{"click":_vm.handlePasswordVisible},slot:"suffix"}),_vm._t("prepend",null,{"slot":"prepend"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }