<template>
  <el-select :value="selected"
             :loading="loading"
             :placeholder="placeholder"
             :clearable="clearable"
             :multiple="multiple"
             :filterable="filterable"
             :size="size"
             :disabled="disabled"
             :filter-method="onSearch"
             @clear="onClear"
             @change="onchange">
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item[label]"
      remote
      :value="item[value]">
    </el-option>
    <div style="bottom: 0;width: 100%;background: #fff">
      <el-pagination
        small
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :current-page="page.current"
        :page-size="page.pageSize"
        layout="prev, pager,next,total"
        :total="page.total">
      </el-pagination>
    </div>
  </el-select>

</template>

<script>

export default {
  name: 'selectPage',
  props: {
    // 已选中的项
    selected: [String, Array],
    label: {
      type: String,
      default: 'label'
    },
    value: {
      type: String,
      default: 'value'
    },
    // 接口所在模块（文件）名
    apiFile: {
      type: String,
      default: 'suitCourse'
    },
    // 接口方法名称
    apiName: {
      type: String,
      default: 'getList'
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    // 即element-ui定义的size
    size: {
      type: String,
      default: ''
    },
    // 要搜索的关键字名
    filter: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    // 每次请求需带的参数
    params: {
      type: Object,
      default: () => {
      }
    },
    // 默认初始化的时候获取数据渲染
    defaultRender: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      options: [],
      keywords: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      }
    }
  },
  computed: {},
  watch: {
    params: {
      handler: function(val) {
        this.getList()
      },
      deep: true
    }
  },
  created() {
    if (this.defaultRender) this.getList()
  },
  methods: {
    onchange(val) {
      this.$emit('update:selected', val)
      if (this.multiple) {
        const res = this.options.filter(item => {
          let flag = false
          val.forEach(value => {
            if (value === item[this.value]) {
              flag = true
            }
          })
          return flag
        })
        this.$emit('change', res)
      } else {
        this.$emit('change', this.options.filter(item => val === item[this.value])[0])
      }
    },
    onClear() {
      this.keywords = ''
      this.getList()
      this.$emit('update:selected', '')
      this.$emit('change', {})
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.page.currentPage = val
      this.getList()
    },
    onSearch(val) {
      this.$nextTick(() => {
        this.keywords = val
        this.page.currentPage = 1
        this.getList()
      })
    },
    async getList() {
      const params = {
        ...this.params,
        size: this.page.pageSize,
        current: this.page.currentPage
      }
      params[this.filter] = this.keywords

      for (const key in params) {
        if (params[key] === '' || params[key] === 'undefined') {
          delete params[key]
        }
      }

      this.loading = true
      const { data } = await this.$http[this.apiFile][this.apiName](params)
      if (data.code === '0') {
        this.options = data.data.records
        this.page.total = parseInt(data.data.total)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
  $primary: #3E6DF5;
  $error: #ff5700;
  .ym-img-preview {
    background: rgba(0, 0, 0, .5);
    .body {
      text-align: center;
    }
    .img {
      display: inline-block;
      max-width: 900px;
      height: auto;
    }
  }
</style>
