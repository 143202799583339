<template>
  <div id="app" class="app">
    <transition-router>
      <router-view v-if="isRouterAlive" />
    </transition-router>
  </div>
</template>

<script>
export default {
  name: 'app',
  provide() { // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    // this.axios.interceptors.response.use(
    //   response => {
    //     if (response.data.code !== 200) {
    //       this.$message.error(response.data.data.errTip)
    //     }
    //     return response
    //   },
    //   error => {
    //     // Do something with response error
    //     return Promise.reject(error)
    //   }
    // )
  },
  methods: {
    reload() {
      this.isRouterAlive = false // 先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true // 再打开
      })
    }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  font-family: -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  /*color: rgba(255, 255, 255, 0.9);*/
  position: relative;
}
</style>
