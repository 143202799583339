import Vue from 'vue'

/**
 *
 * 注册一个全局自定义指令 `v-errorAlt`
 * 加载错误图片替换默认图
 * */
const courseImg = require('./../assets/img/default-course.png')
const certificateImg = require('./../assets/img/default-certificate.png')
const photoImg = require('./../assets/img/photo.png')

function switchDefaultImg(value) {
  let img = ''
  switch (value) {
      case 'course':
        img = courseImg
        break
      case 'cert':
        img = certificateImg
        break
      case 'photo':
        img = photoImg
        break
      default:
        img = certificateImg
  }
  return img
}

Vue.directive('errorAlt', {
  bind: function(el, binding) {
    if (!el.src) {
      el.setAttribute('src', switchDefaultImg(binding.value))
    }
    el.onerror = () => {
      el.src = switchDefaultImg(binding.value)
      el.onerror = null // 防止闪图
    }
  },
  update: function(el, binding) {
    if (!el.src) {
      el.setAttribute('src', switchDefaultImg(binding.value))
    }
    el.onerror = () => {
      el.src = switchDefaultImg(binding.value)
      el.onerror = null // 防止闪图
    }
  }
})
