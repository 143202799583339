const CONFIG = {}
let WIN_CONFIG = {}

if (process.env.NODE_ENV === 'production') {
  WIN_CONFIG = window.API_CONFIG.prod
} else {
  WIN_CONFIG = window.API_CONFIG.dev
}

for (const key in WIN_CONFIG) {
  CONFIG[key] = WIN_CONFIG[key]
}

export default {
  ...CONFIG,
  install(Vue, options) {
    for (const key in CONFIG) {
      Vue.prototype[key] = CONFIG[key]
    }
  }
}
