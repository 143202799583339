import axios from '../../plugins/axios'

// 查询搜索产品名称
const listProducts = (options) => axios.post('/vervel/listProducts', options)
// 查询搜索产品批次编号
const listAfcBatchs = (options) => axios.post('/vervel/listAfcBatchs', options)
// 查询搜索种源提供商（companyTypeId:101）
const listCompanys = (options) => axios.post('/base/listCompanys', options)

export default {
  listProducts,
  listAfcBatchs,
  listCompanys
}
