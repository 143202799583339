import axios from '../../plugins/axios'
// 登录
const login = (options) => axios.post('/base/userLogin', options)
// 登出
const logout = (options) => axios.post('/user/logout', options)
// 用户信息
// const getUser = (options) => axios.post('/user/getUser', options)
// 发送验证码
// const sendCode = (options) => axios.post('/user/sendVerificationCode', options)
// 忘记密码
// const forgetPaws = (options) => axios.post('/user/update/login/pwd', options)
export default {
  login,
  logout
  // getUser,
  // sendCode,
  // forgetPaws
}
