import axios from '../../plugins/axios'
// 用户信息（查询员工）设置
const getUser = (options) => axios.post('/base/getUser', options)
// 修改自己密码
const updateUserPassword = (options) => axios.post('/base/updateUserPassword', options)
// 修改员工(手机号码)
const updateUser = (options) => axios.post('/base/updateUser', options)
// 查询公司(企业)
const getCompany = (options) => axios.post('/base/getCompany', options)
// 获取公司证件类型
const listCompanyCertType = (options) => axios.post('/base/listCompanyCertType', options)
// 修改公司(企业)
const updateCompany = (options) => axios.post('/base/updateCompany', options)

export default {
  getUser,
  updateUserPassword,
  updateUser,
  getCompany,
  listCompanyCertType,
  updateCompany
}
