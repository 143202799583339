<template>
  <div class="footer">
    <div class="footerInner">
      <!-- <div class="footer-email">
        事务邮箱：
        <el-link class="email-font" :underline="false" target="_blank">xxxyyyy@163.com</el-link>
      </div>
      <div class="footer-phone">客服热线：020-2*****8</div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'smallFooter',
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  background: rgba(246, 246, 248, 1);
  .footerInner {
    width: 500px;
    height: 50px;
    display: flex;
    flex-direction: row;
    .footer-email {
      width: 300px;
      height: 50px;
      line-height: 50px;
      text-align: left;
      text-decoration: none;
    }
    .footer-phone {
      width: 200px;
      height: 50px;
      line-height: 50px;
      text-align: right;
    }
  }
}
</style>
