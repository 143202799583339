'use strict'

import Vue from 'vue'
import axios from 'axios'
import baseUrl from '@/utils/getConfig.js'
import store from '@/store'
import { MessageBox } from 'element-ui'
import md5 from 'js-md5'
const Base64 = require('js-base64').Base64
// import Qs from 'qs'

const config = {
  baseURL: baseUrl.baseUrl,
  // timeout: 10000,
  timeout: 100000,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache,no-store,max-age=-1,private',
    'Content-Type': 'application/json;charset=utf-8'
  }
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function(config) {
    // 统一给请求头添加token(sessionId)
    let userInfo = {}
    if (store.state.user.userInfo) {
      userInfo = JSON.parse(store.state.user.userInfo)
    }
    config.headers.sessionId = store.state.user.token || ''
    config.headers.appId = userInfo.companyId || ''
    config.headers.format = 'json' // 默认json,目前支持格式为json
    config.headers.version = '1.0'
    config.headers.timestamp = (new Date()).getTime()
    config.headers.callbackFlag = '1' // 回调标识，1=不回调 2=回调
    if (config.method === 'post') {
      config.headers.sign = md5((userInfo.secretKey || '') + config.headers.appId + Base64.encode(JSON.stringify(config.data)) + config.headers.format + config.headers.sessionId + config.headers.timestamp + config.headers.version + config.url + (userInfo.secretKey || ''))
    }
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    const res = response.data

    if (res.code === '100000') {
      const isAsking = store.state.user.isAskLogin

      if (!isAsking) {
        store.commit('SET_IS_ASK_LOGIN', true)
        MessageBox.confirm('您当前已被登出或未登录，请重新登录', '重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          store.dispatch('logout')
        })
      }

      // return Promise.reject(new Error(res.message || 'Error'))
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

Plugin.install = function(Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default _axios
