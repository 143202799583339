<template>
  <el-header class="ym-header" style="flex-direction: column;padding:0;">
    <div class="headerWrap">
      <div class="headerInner">
        <div class="userInfo">
          <span>您好，{{ userInfo ? userInfo.companyName : '未登录' }}-{{ userInfo ? userInfo.userName : '' }}</span>
        </div>
        <div>|</div>
        <div class="imgWrap">
          <el-link @click="loginOut">退出</el-link>
        </div>
      </div>
    </div>
    <div class="headerMenu">
      <div class="headerMenuInner">
        <img
          class="headerTitle"
          src="../../assets/img/header-title.png"
          alt=""
        />
        <el-menu
          :default-active="activePath"
          class="menu-demo"
          mode="horizontal"
          @select="onSelect"
        >
        <template v-for="(item, index) in menus">
          <el-menu-item :index="item.path" :key="item.path+''+index">
            <span>{{item.title}}</span>
          </el-menu-item>
        </template>
        </el-menu>
      </div>
    </div>
  </el-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getActiveMenu, setActiveMenu } from '@/utils/auth.js'
export default {
  name: 'ymHeader',
  data() {
    return {
      activePath: getActiveMenu() || this.$route.path
    }
  },
  created() {
    console.log(this.$route.path)
    this.changePath()
  },
  computed: {
    ...mapGetters(['userInfo']),
    menus() {
      const routes = this.$router.options.routes
      console.log(routes)
      const res = []
      if (!routes || routes.length === 0) {
        return res
      }
      for (let i = 1; i < routes.length; i++) {
        const route = routes[i]

        if (route.meta.menuShow && route.meta.menuName) {
          const menu = {
            path: route.redirect,
            title: route.meta.menuName
          }
          res.push(menu)
        }
      }
      console.log('路由输出:', res)
      return res
    }
  },
  methods: {
    ...mapActions(['logout']),
    loginOut() {
      // removeToken()
      this.$router.push('/login')
      this.logout()
    },
    onSelect(index) {
      setActiveMenu(index)
      this.activePath = index
      this.$router.push(this.activePath)
      if (this.activePath === '/home') {
        this.$emit('keyfunc', 1)
      } else if (this.activePath === '/listBroods') {
        this.$emit('keyfunc', 2)
      } else if (this.activePath === '/setting') {
        this.$emit('keyfunc', 3)
      }
    },
    changePath() {
      // this.activePath = this.$route.path
      if (this.$route.path !== '/home' && this.$route.path !== '/setting' && this.$route.path !== '/setting/changeInfor') {
        this.activePath = '/listBroods'
      } else if (this.$route.path === '/home') {
        this.activePath = '/home'
      } else {
        this.activePath = '/setting'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ym-header {
  height: 96px !important;
  .headerWrap {
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    height: 40px;
    box-sizing: border-box;
    background: rgba(246, 246, 248, 1);
    .headerInner {
      margin: auto;
      width: 1200px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .userInfo {
        margin-right: 16px;
      }
      .imgWrap {
        display: flex;
        align-items: center;
        margin-left: 16px;
      }
    }
  }
  .headerMenu {
    height: 56px;
    background: #3e6df5;
    .headerMenuInner {
      margin: auto;
      width: 1200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .headerTitle {
        width: 472px;
        height: 32px;
        margin-top: 18px;
      }
      .headerButton {
        width: 728px;
        height: 56px;
      }
    }
  }
  .menu-demo {
    margin-left: 344px;
    width: 384px;
    height: 36px;
    line-height: 32px;
    margin-top: 12px;
    background: #3e6df5;
  }
  .el-menu-item {
    color: #fff;
    width: 112px;
    height: 36px;
    line-height: 32px;
    margin-left: 16px;
    text-align: center;
    font-size: 16px;
    border-radius: 18px;
  }
  .el-menu-item:hover {
    color: #3e6df5;
    width: 112px;
    height: 36px;
    line-height: 32px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.86) 0%,
      rgba(255, 255, 255, 1) 51%,
      rgba(255, 255, 255, 1) 100%,
      rgba(255, 255, 255, 0.76) 100%
    );
    border-radius: 18px;
  }
  .is-active {
    color: #3e6df5;
    width: 112px;
    height: 36px;
    line-height: 32px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.86) 0%,
      rgba(255, 255, 255, 1) 51%,
      rgba(255, 255, 255, 1) 100%,
      rgba(255, 255, 255, 0.76) 100%
    );
    border-radius: 18px;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none;
    color: #3e6df5;
  }
  .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
  }
}
</style>
