<template>
  <el-container class="ym-layout" style="flex-direction: column;">
    <Header @keyfunc="getKeyFromSon"></Header>
    <el-container class="ym-container">
      <el-aside v-show="key != 1" width="200px">
        <Menu></Menu>
      </el-aside>
      <el-main v-if="key!=1">
        <router-view />
      </el-main>
      <router-view v-else />
    </el-container>
    <Footer v-show="key != 1"></Footer>
  </el-container>
</template>

<script>
import Header from './Header.vue'
import Menu from './Menu.vue'
import Footer from './smallFooter.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Header,
    Menu,
    Footer
  },
  created() {
    this.getRoute()
  },
  data() {
    return {
      key: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    getRoute() {
      console.log('获取路由:', this.$route.path)
      const path = this.$route.path
      if (path === '/home') {
        this.key = 1
      } else {
        this.key = 0
      }
      console.log(this.key)
    },
    getKeyFromSon(data) {
      console.log('子组件向父组件传值:', data)
      this.key = data
      console.log('test:', this.key)
    }
  }
}
</script>

<style lang="scss">
.ym-layout {
  width: 100%;
  height: calc(100% - 1px);
  background: rgba(246, 246, 248, 1);

  .ym-container {
    background-color: #fff;
    margin-top: 16px;
  }
  .el-aside {
    text-align: left;
    color: #999999;
    box-shadow: 0 0 18px 0 hsla(0, 0%, 57.3%, 0.28);
    /*box-shadow:4px 3px 6px rgba(54,121,252,0.04);*/
  }
  .el-main {
    background-color: #fff;
    border-left: 1px solid #DDDDDD;
  }
}
</style>
