import axios from '../../plugins/axios'
// 查询检疫信息列表
const listQuarantines = (options) => axios.post('/vervel/listQuarantines', options)
// 查询检疫信息
const getQuarantine = (options) => axios.post('/vervel/getQuarantine', options)
// 增加检疫信息
const saveQuarantine = (options) => axios.post('/vervel/saveQuarantine', options)
// 修改检疫信息
const updateQuarantine = (options) => axios.post('/vervel/updateQuarantine', options)
// 删除检疫信息
const removeQuarantine = (options) => axios.post('/vervel/removeQuarantine', options)
// 下载检疫报告
const downloadQuarantineReport = (options) => axios.post('/report/downloadQuarantineReport', options)
// 查询下载检疫报告结果
const getDownloadQuarantineReport = (options) => axios.post('/report/getDownloadQuarantineReport', options)

export default {
  listQuarantines,
  getQuarantine,
  saveQuarantine,
  updateQuarantine,
  removeQuarantine,
  downloadQuarantineReport,
  getDownloadQuarantineReport
}
