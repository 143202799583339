import axios from '../../plugins/axios'
// 查询饲料信息列表
const listFeeds = (options) => axios.post('/vervel/listFeeds', options)
// 查询饲料信息
const getFeed = (options) => axios.post('/vervel/getFeed', options)
// 增加饲料信息
const saveFeed = (options) => axios.post('/vervel/saveFeed', options)
// 修改饲料信息
const updateFeed = (options) => axios.post('/vervel/updateFeed', options)
// 删除饲料信息
const removeFeed = (options) => axios.post('/vervel/removeFeed', options)

export default {
  listFeeds,
  getFeed,
  saveFeed,
  updateFeed,
  removeFeed
}
