import Vue from 'vue'
import Router from 'vue-router'
import Layout from './../components/Layout/index.vue'

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import('../views/login/login.vue'),
      redirect: '/signin',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/signin',
          component: () => import('../views/login/signin.vue'),
          meta: { menuShow: false, menuName: '登录' },
          // this.$route
          beforeEnter(to, from, next) {
            if (to.query.__new) return next()
            window.location.href = router.resolve({
              path: to.fullPath,
              query: { ...to.query, __new: true }
            }).href
            window.location.reload()
            return next(false)
          }
        }
        // {
        //   path: '/signup',
        //   component: () => import('../views/login/signup.vue'),
        //   meta: { menuShow: false, menuName: '注册' }
        // },
        // {
        //   path: '/forgot',
        //   component: () => import('../views/login/forgot.vue'),
        //   meta: { menuShow: false, menuName: '忘记密码' }
        // }
      ]
    },

    // 首页总览
    {
      path: '/',
      component: Layout,
      redirect: '/home',
      meta: {
        menuShow: true,
        menuName: '首页总览'
      },
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('../views/home/index.vue'),
          meta: {
            menuShow: true,
            menuName: '首页总览',
            icon: 'el-icon-user'
          }
        }
      ]
    },

    // 信息管理
    {
      path: '/',
      component: Layout,
      redirect: '/listBroods',
      meta: {
        menuShow: true,
        menuName: '信息管理'
      },
      children: [
        // 育雏信息
        {
          path: '/listBroods',
          name: 'listBroods',
          component: () => import('../views/broodInfor/listBroods.vue'),
          meta: {
            menuShow: true,
            menuName: '育雏信息',
            icon: 'iconfont iconyuchu'
          }
        },
        // 饲料信息
        {
          path: '/listFeeds',
          name: 'listFeeds',
          component: () => import('../views/feedInfor/listFeeds.vue'),
          meta: {
            menuShow: true,
            menuName: '饲料信息',
            icon: 'iconfont iconsiliao'
          }
        },
        // 检疫信息
        {
          path: '/listQuarantines',
          name: 'listQuarantines',
          component: () => import('../views/quarantinesInfor/listQuarantines.vue'),
          meta: {
            menuShow: true,
            menuName: '检疫信息',
            icon: 'iconfont iconjianyi'
          }
        },
        // 出栏信息
        {
          path: '/listFowlOuts',
          name: 'listFowlOuts',
          component: () => import('../views/fowlOutsInfor/listFowlOuts.vue'),
          meta: {
            menuShow: true,
            menuName: '出栏信息',
            icon: 'iconfont iconchulanxinxi'
          }
        }
      ]
    },

    // 账户管理
    {
      path: '/',
      component: Layout,
      redirect: '/setting',
      meta: {
        menuShow: true,
        menuName: '账户管理'
      },
      children: [
        {
          path: '/setting',
          name: 'setting',
          component: () => import('../views/accountManager/setting.vue'),
          meta: {
            menuShow: true,
            menuName: '设置',
            icon: 'iconfont iconshezhi'
          }
        }
      ]
    },

    // 企业资料变更
    {
      path: '/',
      component: Layout,
      redirect: '/setting/changeInfor',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/setting/changeInfor',
          name: 'changeInfor',
          component: () => import('../views/accountManager/changeInfor.vue'),
          meta: {
            menuShow: false,
            menuName: '企业资料变更'
          }
        }
      ]
    },

    {
      path: '/',
      redirect: '/home',
      meta: { menuShow: false, menuName: '首页' }
    },

    // 添加育雏信息
    {
      path: '/',
      component: Layout,
      redirect: '/listBroods/saveBrood',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listBroods/saveBrood',
          name: 'saveBrood',
          component: () => import('../views/broodInfor/saveBrood.vue'),
          meta: {
            menuShow: false,
            menuName: '添加育雏信息'
          }
        }
      ]
    },
    // 育雏信息详情
    {
      path: '/',
      component: Layout,
      redirect: '/listBroods/getBrood',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listBroods/getBrood',
          name: 'getBrood',
          component: () => import('../views/broodInfor/getBrood.vue'),
          meta: {
            menuShow: false,
            menuName: '详情'
          }
        }
      ]
    },
    // 添加饲料信息
    {
      path: '/',
      component: Layout,
      redirect: '/listFeeds/saveFeed',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listFeeds/saveFeed',
          name: 'saveFeed',
          component: () => import('../views/feedInfor/saveFeed.vue'),
          meta: {
            menuShow: false,
            menuName: '添加饲料信息'
          }
        }
      ]
    },
    // 饲料信息详情
    {
      path: '/',
      component: Layout,
      redirect: '/listFeeds/getFeed',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listFeeds/getFeed',
          name: 'getFeed',
          component: () => import('../views/feedInfor/getFeed.vue'),
          meta: {
            menuShow: false,
            menuName: '详情'
          }
        }
      ]
    },
    // 添加检疫信息
    {
      path: '/',
      component: Layout,
      redirect: '/listQuarantines/saveQuarantine',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listQuarantines/saveQuarantine',
          name: 'saveQuarantine',
          component: () => import('../views/quarantinesInfor/saveQuarantine.vue'),
          meta: {
            menuShow: false,
            menuName: '添加检疫信息'
          }
        }
      ]
    },
    // 检疫信息详情
    {
      path: '/',
      component: Layout,
      redirect: '/listQuarantines/getQuarantine',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listQuarantines/getQuarantine',
          name: 'getQuarantine',
          component: () => import('../views/quarantinesInfor/getQuarantine.vue'),
          meta: {
            menuShow: false,
            menuName: '详情'
          }
        }
      ]
    },
    // 添加出栏信息
    {
      path: '/',
      component: Layout,
      redirect: '/listFowlOuts/saveFowlOut',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listFowlOuts/saveFowlOut',
          name: 'saveFowlOut',
          component: () => import('../views/fowlOutsInfor/saveFowlOut.vue'),
          meta: {
            menuShow: false,
            menuName: '添加出栏信息'
          }
        }
      ]
    },
    // 出栏详情
    {
      path: '/',
      component: Layout,
      redirect: '/listFowlOuts/getFowlOut',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listFowlOuts/getFowlOut',
          name: 'getFowlOut',
          component: () => import('../views/fowlOutsInfor/getFowlOut.vue'),
          meta: {
            menuShow: false,
            menuName: '出栏详情'
          }
        }
      ]
    },
    // 出栏明细列表
    {
      path: '/',
      component: Layout,
      redirect: '/listFowlOuts/fowlOutList',
      meta: {
        menuShow: false
      },
      children: [
        {
          path: '/listFowlOuts/fowlOutList',
          name: 'fowlOutList',
          component: () => import('../views/fowlOutsInfor/fowlOutList.vue'),
          meta: {
            menuShow: false,
            menuName: '出栏明细列表'
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/home',
      meta: { menuShow: false, menuName: '首页总览' }
    }
  ]
})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
