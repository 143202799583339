import router from '@/router'
import { getUserInfo } from '@/utils/auth' // get token from cookie
import store from '@/store'

// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  // console.log(store.state.role.routers)
  const hasToken = store.state.user.userInfo || getUserInfo()
  // debugger
  if (to.path === '/signin') {
    next()
  } else if (!hasToken || hasToken === 'undefined') {
    next({
      path: '/signin',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

