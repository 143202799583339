import TransitionRouter from './transition/transition-router.vue'
import NoRecord from './NoRecord/index.vue'
import InputPassword from './InputPassword'
import SelectPage from './selectPage.vue'
import UploadFile from './uploadFile.vue'

const myComponents = {
  install: function(Vue) {
    Vue.component('TransitionRouter', TransitionRouter)
    Vue.component('NoRecord', NoRecord)
    Vue.component('input-password', InputPassword)
    Vue.component('SelectPage', SelectPage)
    Vue.component('UploadFile', UploadFile)
  }
}

// 导出组件
export default myComponents
